import { useEffect, useRef } from "react";
import * as styles from "./app.module.scss";

const data = (window as any).app.preloadState.pdpImageSlider;
const mediaUrls = data.mediaUrls;

function App() {
    const mainSlider = useRef<HTMLDivElement>(null);
    const thumbnailSlider = useRef<HTMLDivElement>(null);
    const prevButton = useRef<HTMLButtonElement>(null);
    const nextButton = useRef<HTMLButtonElement>(null);
    const prevMainButton = useRef<HTMLButtonElement>(null);
    const nextMainButton = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const main = mainSlider.current!;
        const thumb = thumbnailSlider.current!;

        const checkButtons = () => {
            prevButton.current!.hidden = thumb.scrollLeft === 0;
            nextButton.current!.hidden = thumb.scrollLeft >= (thumb.scrollWidth - thumb.offsetWidth) - 8;
            prevMainButton.current!.hidden = main.scrollLeft === 0;
            nextMainButton.current!.hidden = main.scrollLeft >= (main.scrollWidth - main.offsetWidth) - 8;
        }
        checkButtons();

        const onscroll = () => {
            checkButtons();
        };

        main.addEventListener('scroll', onscroll);
        thumb.addEventListener('scroll', onscroll);
        return () => {
            main.removeEventListener('scroll', onscroll);
            thumb.removeEventListener('scroll', onscroll);
        }
    }, []);

    const prevMainImage = () => {
        const main = mainSlider.current!;
        main.scrollLeft -= main.scrollWidth / mediaUrls.length;
    };

    const nextMainImage = () => {
        const main = mainSlider.current!;
        main.scrollLeft += main.scrollWidth / mediaUrls.length;
    }

    const prevImage = () => {
        const thumb = thumbnailSlider.current!;
        thumb.scrollLeft -= thumb.offsetWidth;
    };

    const nextImage = () => {
        const thumb = thumbnailSlider.current!;
        thumb.scrollLeft += thumb.offsetWidth;
    }

    const toThatImage = (index: number) => () => {
        const main = mainSlider.current!;
        main.scrollLeft = index * main.scrollWidth / mediaUrls.length;
    }

    return <div className={styles.sliderParent}>
        <div className={styles.sliderWrapper}>
            <div className={styles.pdpImageSlider} ref={mainSlider}>
                {mediaUrls.map((mediaUrl: string) => (
                    <div key={mediaUrl} className={styles.imageContainer}>
                        <img src={mediaUrl} />
                    </div>
                    ))}
            </div>
        </div>
        <div className={styles.pdpMiniatureImages} ref={thumbnailSlider}>
            {mediaUrls.map((mediaUrl: string, i: number) => (
                <div key={mediaUrl} className={styles.miniImageContainer} onClick={toThatImage(i)}>
                    <img src={mediaUrl} />
                </div>
            ))}
        </div>
        <button className={styles.prevMainButton} onClick={prevMainImage} ref={prevMainButton}></button>
        <button className={styles.nextMainButton} onClick={nextMainImage} ref={nextMainButton}></button>
        <button className={styles.prevButton} onClick={prevImage} ref={prevButton}></button>
        <button className={styles.nextButton} onClick={nextImage} ref={nextButton}></button>
    </div>
}

export default App;
